<template>
  <div class="profile-legal">
    <div class="logo" @click="$router.back()">
      <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee" />
    </div>
    <div class="content-wrapper">
      <div class="content">
        <transition name="fade" mode="out-in">
          <CookieSettings v-if="showSettings" @closeCookieSettings="closeComponent"></CookieSettings>
        </transition>

        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="$router.back()">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
          <div class="cookie-settings" @click="showCookieSettings">
            <img src="@/assets/icons/settings.svg" alt="Settings" />
            <p>Cookie Einstellungen</p>
          </div>
        </div>

        <!-- Tab Navigation -->
        <div class="tab-navigation">
          <div class="legal-notice" @click="showTabComponent('LegalNotice')" :class="{ active: component === 'LegalNotice' }">
            Impressum
          </div>
          <div class="privacy-policy" @click="showTabComponent('PrivacyPolicy')" :class="{ active: component === 'PrivacyPolicy' }">
            Datenschutz
          </div>
        </div>

        <!-- Content -->
        <simplebar data-simplebar-auto-hide="false" class="legal__wrapper">
          <component :is="component" />
        </simplebar>
      </div>
    </div>
  </div>
</template>

<script>
import LegalNotice from "@/components/LegalNotice.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import CookieSettings from "@/components/CookieSettings.vue";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "Profile-Legal",
  components: {
    LegalNotice,
    PrivacyPolicy,
    CookieSettings,
    simplebar,
  },
  data() {
    return {
      showSettings: false,
    };
  },
  methods: {
    showTabComponent(name) {
      this.$store.commit("SET_LEGAL_COMPONENT", name);
    },
    showCookieSettings() {
      this.showSettings = true;
    },
    closeComponent(data) {
      this.showSettings = data;
    },
  },
  computed: {
    component() {
      return this.$store.getters.LEGALCOMPONENT;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-legal {
  min-height: calc(100vh - 83px);
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 960px) {
    padding-top: 20px;
    position: fixed;
    width: 100%;
  }
  .logo {
    margin-bottom: 48px;
    cursor: pointer;
    @media screen and (max-width: 960px) {
      margin-bottom: 24px;
    }
  }
  .content-wrapper {
    width: 100%;
    background-color: $darker-navy-blue;
    height: calc(100vh - 283px);
    @media screen and (max-width: 960px) {
      height: calc(100vh - 136px);
    }
    .content {
      position: relative;
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
        }
        .back,
        .cookie-settings {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
        .cookie-settings {
          img {
            margin-right: 4px;
            transform: translateY(-1px);
          }
        }
      }
      .tab-navigation {
        width: 800px;
        background-color: $navy-blue;
        z-index: 200;
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;
        @media screen and (max-width: 960px) {
          height: 40px;
          width: 100%;
        }
        .legal-notice,
        .privacy-policy {
          width: 50%;
          height: 40px;
          border: 1px solid $white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
        }
        .legal-notice {
          border-radius: 5px 0 0 5px;
        }
        .privacy-policy {
          border-radius: 0 5px 5px 0;
        }
      }
      .legal__wrapper {
        height: calc(100vh - 432px);
        padding-right: 12px;
        @media screen and (max-width: 960px) {
          height: calc(100vh - 342px);
        }
      }
    }
  }
  .active {
    background-color: $light-grey;
  }
}
</style>
